import React, { useState, useEffect } from "react";
import axios from "axios";
import "./schedules.css";
import swal from "sweetalert";
import { saveAs } from "file-saver";

import { API_URLS, BASE_URL } from "../../../../Utils";

const Schedules = () => {
  const [schedules, setSchedules] = useState([]);
  const [activeTab, setActiveTab] = useState("Standings");
  const [selectedYear, setSelectedYear] = useState("2024");
  const [tournamentList, setTournamentList] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState("");
  const [selectedTournamentName, setSelectedTournamentName] = useState(null);
  const [latestFile, setLatestFile] = useState(null);
  const [fileDetails, setFileDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sixtylatestFile, setSixtyLatestFile] = useState(null);
  const [sixtyfileDetails, setSixtyFileDetails] = useState([]);
  const [sixtyloading, setsixtyLoading] = useState(true);
  const [sixtyerror, setsixtyError] = useState(null);
  const [pdfSchedule, setPdfSchedule] = useState([]);
  const [pdfFile, setPdfFiles] = useState([]);
  const [countdown, setCountdown] = useState(null);
  const [tournamentStarted, setTournamentStarted] = useState(false);
  const fetchUpcomingTournaments = async () => {
    try {
      const response = await axios.get(API_URLS.UP_COMING_PROAM_TOURNAMENTS);

      if (response.status === 200) {
        const upcomingTours = response.data.data.result[0]; // Extracting the array of tours
        const today = new Date();

        const filteredTours = upcomingTours.filter((tournament) => {
          const tournamentDate = new Date(tournament.ProAmPlayingDate);
          return tournamentDate >= today;
        });

        filteredTours.sort((a, b) => {
          return new Date(a.ProAmPlayingDate) - new Date(b.ProAmPlayingDate);
        });

        if (filteredTours && filteredTours.length > 0) {
          startCountdown(filteredTours[0].ProAmPlayingDate);
        }
      } else {
        console.error("Failed to fetch upcoming tournaments");
      }
    } catch (error) {
      console.error("Error fetching upcoming tournaments:", error);
    }
  };

  const startCountdown = (tournamentDate) => {
    // Parse the UTC tournament date to milliseconds since the epoch
    const countDownDate = new Date(tournamentDate).getTime(); // This is in UTC

    const timerInterval = setInterval(() => {
      const now = new Date().getTime(); // Local time in milliseconds
      const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000; // Offset in milliseconds
      const adjustedNow = now - timezoneOffset; // Adjust local time to UTC time

      const distance = countDownDate - adjustedNow; // Calculate the difference

      if (distance < 0) {
        clearInterval(timerInterval);
        setCountdown(null);
        setTournamentStarted(true);
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    }, 1000);
  };

  // Call the function with your tournament date

  const formatTime = (time) => {
    let [hourPart, minutePart] = time.split(":");
    let minutes, period;

    if (minutePart) {
      [minutes, period] = minutePart.split(" ");
      period = period ? period.toUpperCase() : "AM";
    } else {
      minutes = "00";
      period = "AM";
    }

    let hours = parseInt(hourPart, 10);

    if (period !== "AM" && period !== "PM") {
      period = hours >= 12 ? "PM" : "AM";
    }

    if (hours > 12) {
      hours -= 12;
      period = "PM";
    } else if (hours === 12) {
      period = period === "AM" ? "PM" : "AM";
    } else if (hours === 0) {
      hours = 12;
      period = "AM";
    }

    hours = String(hours).padStart(2, "0");
    minutes = minutes.padStart(2, "0");

    return `${hours}:${minutes} ${period}`;
  };
  // Helper function to convert time string to a Date object for sorting
  const parseTimeToDate = (time) => {
    const normalizedTime = time.trim().toLowerCase();
    const [timePart, period] = normalizedTime.split(/\s+/);
    const [hour, minute] = timePart.split(":");

    // Adjust hours based on AM/PM
    const hours = period === "pm" && +hour !== 12 ? +hour + 12 : +hour % 12;

    // Create a new Date object in UTC
    const date = new Date(Date.UTC(1970, 0, 1, hours, +minute));

    // Convert to local timezone
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  };
  const getSchedule = async () => {
    try {
      const url = `${BASE_URL}/api/v1/web/park/get_all_park_schedules?ParkId=${selectedTournament}&SeasonOrTour=${selectedTournamentName}&Year=${selectedYear}`;

      try {
        const response = await axios.get(url); // Ensure axios is used correctly

        if (response.status === 200) {
          const data = response.data.data.result;
          const filteredData = data.filter((item) => item.TeamPhoto);
          //console.log("first", data);

          if (data != null && Array.isArray(data)) {
            // Map and format the schedule
            const formattedSchedules = data.map((schedule) => ({
              ...schedule,
              Date: new Date(schedule.Date).toLocaleDateString(),
              Time: formatTime(schedule.Time),
              DayOfWeek: new Date(schedule.Date).toLocaleDateString("en-US", {
                weekday: "long",
              }), // Extract the day of the week
            }));

            // Sort by DayOfWeek and then by Time
            const sortedSchedules = formattedSchedules.sort((a, b) => {
              const dayOrder = [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ];
              const dayIndexA = dayOrder.indexOf(a.DayOfWeek);
              const dayIndexB = dayOrder.indexOf(b.DayOfWeek);

              // First sort by DayOfWeek
              if (dayIndexA !== dayIndexB) {
                return dayIndexA - dayIndexB;
              }

              // Then sort by Time
              const timeA = parseTimeToDate(a.Time);
              const timeB = parseTimeToDate(b.Time);
              return timeA - timeB;
            });

            setSchedules(sortedSchedules);
          } else {
            swal("No schedule found for the selected tournament.");
          }
        } else {
          throw new Error(
            "Failed to fetch schedule data. Status code: " + response.status
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const fnTournamentList = () => {
    //debugger;
    const url = `${BASE_URL}/api/v1/web/park/get_all_proam_tournaments_by_year?year=${selectedYear}`;
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        setTournamentList(data.data.result);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
  const getAllPdfSchedule = async (tournament) => {
    try {
      const url = `${BASE_URL}/api/v1/web/park/getAll_PDF_Schedule?ParkId=${tournament}&Type=Tournament&Year=${selectedYear}`;

      try {
        const response = await axios(url);

        if (response.status === 200) {
          const data = response.data.data.result;
          // Filter out invalid or empty ScheduleFiles
          const filteredData = data.filter(
            (item) => item.ScheduleFile && item.ScheduleFile.trim() !== ""
          );

          // Update state with the latest ScheduleFile
          setPdfSchedule(filteredData);
          //console.log("Latest filtered data:", filteredData);
        } else {
          throw new Error(
            "Failed to fetch schedule data. Status code: " + response.status
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const Download50Standing = async () => {
    if (latestFile) {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/v1/files/FiftyStanding/${latestFile.name}`,
          { responseType: "blob" }
        );
        if (response.status === 200) {
          // Default file name
          const defaultFileName = "50sPointsStandings";
          // Create a Blob from the response data
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const blobUrl = URL.createObjectURL(blob);
          // Open the PDF in a new tab
          window.open(blobUrl, "_blank");
          //saveAs(blob, defaultFileName);
        }
      } catch (err) {
        console.error("Error downloading file:", err);
      }
    } else {
      console.error("No file available to download");
    }
  };
  const DownloadPdfSchedule = async (files, filename) => {
    if (files) {
      try {
        const response = await axios.get(
          ` ${BASE_URL}/api/v1/files/PDFSchedule/${files}`,
          { responseType: "blob" }
        );
        if (response.status === 200) {
          const defaultFileName = filename;

          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const blobUrl = URL.createObjectURL(blob);
          // Open the PDF in a new tab
          window.open(blobUrl, "_blank");
          //saveAs(blob, defaultFileName);
        }
      } catch (err) {
        console.error("Error downloading file:", err);
      }
    } else {
      console.error("No file available to download");
    }
  };

  const Download60Standing = async () => {
    if (latestFile) {
      try {
        const response = await axios.get(
          ` ${BASE_URL}/api/v1/files/SixtyStanding/${sixtylatestFile.name}`,
          { responseType: "blob" }
        );
        if (response.status === 200) {
          const defaultFileName = "60sPointsStandings";

          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const blobUrl = URL.createObjectURL(blob);
          // Open the PDF in a new tab
          window.open(blobUrl, "_blank");
          //saveAs(blob, defaultFileName);
        }
      } catch (err) {
        console.error("Error downloading file:", err);
      }
    } else {
      console.error("No file available to download");
    }
  };

  const baseCountdownStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "1.5rem",
    fontFamily: "Arial, sans-serif",
  };

  const isSmallScreen = window.innerWidth <= 767;

  const countdownStyle = {
    ...baseCountdownStyle,
    fontSize: isSmallScreen ? "1rem" : "1.5rem", // Adjust font size for smaller screens
  };

  const countdownItemStyle = {
    textAlign: "center",
  };

  useEffect(() => {
    fnTournamentList();
    fetchUpcomingTournaments();
  }, [selectedYear]);

  useEffect(() => {
    if (selectedTournament !== "") {
      getSchedule();
    }
  }, [selectedTournament]);

  useEffect(() => {
    const fetchFileDetails = async () => {
      try {
        const response = await axios.get(API_URLS.GET_PDF_SCHEDULE);
        if (response.status === 200) {
          const files = response.data.data;

          setPdfFiles(files);
        }
      } catch (err) {
        setError("Error fetching file details");
      } finally {
        setLoading(false);
      }
    };

    fetchFileDetails();
  }, []);

  useEffect(() => {
    const fetchFileDetails = async () => {
      try {
        const response = await axios.get(API_URLS.GET_FIFTY_STANDING);
        if (response.status === 200) {
          const files = response.data.data;
          const sortedFiles = files.sort(
            (a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate)
          );
          const mostRecentFile = sortedFiles[0];
          setFileDetails(files);
          setLatestFile(mostRecentFile);
        }
      } catch (err) {
        setError("Error fetching file details");
      } finally {
        setLoading(false);
      }
    };

    fetchFileDetails();
  }, []);
  useEffect(() => {
    const fetch60FileDetails = async () => {
      try {
        const sixtyresponse = await axios.get(API_URLS.GET_SIXTY_STANDING);
        if (sixtyresponse.status === 200) {
          const sixtyfiles = sixtyresponse.data.data;

          const sixtysortedFiles = sixtyfiles.sort(
            (a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate)
          );
          const sixtymostRecentFile = sixtysortedFiles[0];
          setSixtyFileDetails(sixtyfiles);
          setSixtyLatestFile(sixtymostRecentFile);
        }
      } catch (err) {
        setsixtyError("Error fetching file details");
      } finally {
        setsixtyLoading(false);
      }
    };

    fetch60FileDetails();
  }, []);
  return (
    <section className="match_section py-5" id="schedule">
      <div className="container" style={{ maxWidth: "70%" }}>
        <div className="sc_title">
          <small>WHAT'S TRENDING</small>
          <h3>NEXT TOURNAMENT</h3>
        </div>

        <div>
          {!tournamentStarted ? (
            <div className="countdown" style={countdownStyle}>
              <div style={countdownItemStyle}>
                <span>{countdown?.days || 0}</span> Days
              </div>
              <div style={countdownItemStyle}>
                <span>{countdown?.hours || 0}</span> Hours
              </div>
              <div style={countdownItemStyle}>
                <span>{countdown?.minutes || 0}</span> Minutes
              </div>
              <div style={countdownItemStyle}>
                <span>{countdown?.seconds || 0}</span> Seconds
              </div>
            </div>
          ) : (
            <div className="tournament-started">Tournament Started</div>
          )}
        </div>

        <div className="tabs_wrapper" data-aos="fade" data-aos-delay="50">
          <div className="table_wrap">
            <div className="tab_wrap">
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                className="selection"
              >
                {/* <select value="2024" className="selection"> */}
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </select>

              <select
                value={selectedTournament}
                onChange={(e) => {
                  setSelectedTournament(e.target.value);
                  const selectedTournamentItem = tournamentList.find(
                    (tournament) =>
                      tournament.ParkId === parseInt(e.target.value, 10)
                  );
                  setSelectedTournamentName(
                    selectedTournamentItem
                      ? selectedTournamentItem.FirstName
                      : null
                  );
                  getAllPdfSchedule(e.target.value);
                }}
                className="selection"
              >
                <option>Select Tournament</option>
                {tournamentList.map((tournament) => (
                  <option key={tournament.ParkId} value={tournament.ParkId}>
                    {tournament.FirstName}
                  </option>
                ))}
              </select>
              <span
                id="Schedules"
                className={`tab ${activeTab === "Schedules" ? "active" : ""}`}
                onClick={() => setActiveTab("Schedules")}
              >
                Schedules
              </span>
              {/* <select name="" id="" className="selection">
                  <option value="">Select Tournament</option>
                  <option value="">Tournament 02</option>
                  <option value="">Tournament 03</option>
                </select> */}
            </div>
            <div className="tab_wrap">
              <span
                id="PDF Schedule"
                className={`tab ${
                  activeTab === "PDF Schedule" ? "active" : ""
                }`}
                onClick={() => setActiveTab("PDF Schedule")}
              >
                PDF Schedule
              </span>
            </div>
            <div className="tab_wrap">
              <span
                id="Standings"
                className={`tab ${activeTab === "Standings" ? "active" : ""}`}
                onClick={() => setActiveTab("Standings")}
              >
                Standings
              </span>
            </div>
          </div>
          <div className="tabs_container">
            {/* Schedule */}
            {activeTab === "Schedules" && (
              <div className="tab_content active" data-tab="Schedules">
                <div
                  className="table-container"
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>Day</th>
                        <th>Time</th>
                        <th className="team">Visitor Team</th>
                        <th>Visitor Score</th>
                        <th>Home Score</th>
                        <th className="team">Home Team</th>
                        <th>Field Number</th>
                        <th>Division</th>
                      </tr>
                    </thead>
                    <tbody>
                      {schedules && schedules.length > 0 ? (
                        schedules.map((schedule, index) => (
                          <tr key={index} className="schedule-item">
                            <td>{schedule.DayOfWeek}</td>
                            <td>{schedule.Time}</td>
                            <td className="team">
                              <img
                                src={
                                  schedule.VisitorTeam ===
                                  schedule.VisitorTeamName
                                    ? schedule.VisitorTeamPhoto
                                      ? `${BASE_URL}/api/v1/files/TeamPhoto/${schedule.VisitorTeamPhoto}`
                                      : "images/team-2.png"
                                    : "images/team-2.png"
                                }
                                style={{ maxWidth: "150px" }}
                                alt="Team Photo"
                              />
                              <div className="content">
                                <strong>{schedule.VisitorTeam}</strong>
                              </div>
                            </td>
                            <td>{schedule.VisitorScores}</td>
                            <td>{schedule.HomeScores}</td>
                            <td className="team">
                              <img
                                src={
                                  schedule.HomeTeam === schedule.HomeTeamName
                                    ? schedule.HomeTeamPhoto
                                      ? `${BASE_URL}/api/v1/files/TeamPhoto/${schedule.HomeTeamPhoto}`
                                      : "images/team-2.png"
                                    : "images/team-2.png"
                                }
                                style={{ maxWidth: "150px" }}
                                alt="Team Photo"
                              />
                              <div className="content">
                                <strong>{schedule.HomeTeam}</strong>
                              </div>
                            </td>
                            <td>{schedule.FieldNumber}</td>
                            <td>{schedule.Division}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="8"
                            style={{ textAlign: "center", padding: "20px" }}
                          >
                            Please Select The Played Tournament From Drop-Down
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {/* Standings */}
            {activeTab === "Standings" && (
              <div className="tab_content active" data-tab="Standings">
                <div
                  className="row"
                  style={{ padding: "30px", cursor: "pointer" }}
                >
                  <a onClick={Download50Standing}>
                    Download 50's Team Standings
                  </a>
                </div>
                <div
                  className="row"
                  style={{ padding: "30px", cursor: "pointer" }}
                >
                  <a onClick={Download60Standing}>
                    Download 60's Team Standings
                  </a>
                </div>
              </div>
            )}
            {activeTab === "PDF Schedule" && (
              <div className="tab_content active" data-tab="PDF Schedule">
                {pdfSchedule && pdfSchedule.length > 0 ? (
                  <>
                    {pdfSchedule?.map((scheduless, index) => (
                      <div
                        className="row"
                        style={{ padding: "30px", cursor: "pointer" }}
                      >
                        <a
                          key={index}
                          onClick={() =>
                            DownloadPdfSchedule(
                              scheduless.ScheduleFile,
                              scheduless.PDFName
                            )
                          }
                        >
                          Download {scheduless.PDFName}
                        </a>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <h3 style={{ textAlign: "center", padding: "20px" }}>
                      Please select a tournament from the dropdown that has an
                      available PDF schedule.
                    </h3>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export { Schedules };
