import React, { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_URLS } from "../../../../../Utils";

const PaymentSuccessAnnualFee = () => {
  const TeamId = window.sessionStorage.getItem("TeamId");

  const updatePaymentStatus = async () => {
    try {
      await axios.post(API_URLS.UPDATE_Annual_Subscription, { TeamId });
      return true; // Indicate success
    } catch (error) {
      console.error("Error updating payment status:", error);
      return false; // Indicate failure
    }
  };

  useEffect(() => {
    updatePaymentStatus();
  }, []);

  return (
    <div className="payment-success-container">
      <div className="checkmark">✓</div>
      <h1 className="ph1">Payment Successful!</h1>
      <p className="paymentp">
        Thank you for your purchase. Your payment has been successfully
        processed.
      </p>
      <Link to="/admin" className="btn">
        Go to Home
      </Link>
    </div>
  );
};

export default PaymentSuccessAnnualFee;
