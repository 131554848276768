import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { API_URLS } from "../../../Utils";
import { Alert } from "../../../components/Alert";
import { useParams } from "react-router-dom";
import EditPlayerModal from "./components/EditPlayerModal";
import constants from "../../../utils/constants";

const PlayerTable = ({ players, onEdit, onDelete, onRefresh }) => {
  const userRole = window.sessionStorage.getItem("UserType");
  const isSuperAdmin = userRole === constants.scopes.SUPERADMIN;
  const isCoach = userRole === constants.scopes.COACH;
  const TeamIDfromCoach = window.sessionStorage.getItem("TeamId");
  const [teamName, setTeamName] = useState("");
  const [editingPlayerId, setEditingPlayerId] = useState(null);
  const [newLineup, setNewLineup] = useState("");

  const handleEditClick = (playerId, playerLineup) => {
    setEditingPlayerId(playerId);
    setNewLineup(playerLineup); // Initialize with the current lineup value
  };

  const handleLineupChange = (e) => {
    setNewLineup(e.target.value);
  };
  const handleDelete = async (id, firstName, lastName) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${firstName} ${lastName}?`
    );

    if (confirmDelete) {
      try {
        await axios.delete(API_URLS.DELETE_PLAYERS_BY_ID_TEAM_ID, {
          params: { PlayerId: id, TeamId: TeamIDfromCoach },
        });

        alert("Player deleted successfully.");
        onRefresh();
      } catch (error) {
        // console.error("Error deleting player:", error);
        alert("Failed to delete player.");
      }
    } else {
      alert("Player deletion canceled.");
    }
  };
  const handleUpdateClick = async (TeamIDfromCoach) => {
    const updatedPlayer = players.find(
      (player) => player.TeamId === editingPlayerId
    );
    if (updatedPlayer) {
      onEdit({ ...updatedPlayer, LineUp: newLineup });
    }
    setEditingPlayerId(null); // Exit edit mode
    try {
      const response = await axios.patch(
        `${API_URLS.UPDATE_TEAM_LINE_UP}?TeamId=${TeamIDfromCoach}&PlayerId=${editingPlayerId}&Position=${newLineup}`
      );

      // setTeamName(data.result[0].TeamName);
      if (response.status === 200) {
        // alert("please Reload to see the updated Lineup");
        onRefresh(); // Refresh the list after successful update
        setEditingPlayerId(null);
      } else {
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const getTeamByTeamId = async (TeamIDfromCoach) => {
    try {
      const {
        data: { data },
      } = await axios.get(API_URLS.GET_TEAM_BY_ID, {
        params: {
          SelectedTeamId: TeamIDfromCoach,
        },
      });
      setTeamName(data.result[0].TeamName);
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  useEffect(() => {
    if (TeamIDfromCoach) {
      getTeamByTeamId(TeamIDfromCoach);
    }
  }, [TeamIDfromCoach]);

  return (
    <div className="container" style={{ maxWidth: "95%" }}>
      <div className="tabs_container">
        <div className="tab_content active" data-tab="Schedules">
          {isCoach && (
            <>
              <h2
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Welcome To Player Management For "{teamName}"
              </h2>
              <p
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                You can edit and Update Lineup
              </p>
            </>
          )}

          <table border="1">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Jersey Number</th>
                <th>State</th>
                <th>City</th>
                <th>Phone No.</th>
                {isCoach && <th>Line Up</th>}
                {isSuperAdmin && <th>Actions</th>}
                {isCoach && <th>Delete Player</th>}
              </tr>
            </thead>
            <tbody>
              {players?.map((player) => (
                <tr key={player?.PlayerId}>
                  <td>{player?.FirstName}</td>
                  <td>{player?.LastName}</td>
                  <td>{player?.PlayerNumber}</td>
                  <td>{player?.State}</td>
                  <td>{player?.City}</td>
                  <td>{player?.PhoneNumber}</td>
                  {isCoach && (
                    <td
                      onClick={() =>
                        handleEditClick(player.PlayerId, player.LineUp)
                      }
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        textDecorationColor: "#fe5900",
                      }}
                    >
                      {editingPlayerId === player.PlayerId ? (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <input
                            type="text"
                            value={newLineup}
                            onChange={handleLineupChange}
                            onClick={(e) => e.stopPropagation()} // Prevent input click from triggering edit mode again
                          />
                          <button
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent button click from triggering edit mode again
                              handleUpdateClick(TeamIDfromCoach);
                            }}
                            className="btn btn_primary"
                            style={{
                              padding: "8px",
                              marginTop: "5px",

                              // Moves the button to the end
                            }}
                          >
                            Update
                          </button>
                        </div>
                      ) : (
                        player.LineUp
                      )}
                    </td>
                  )}

                  {isSuperAdmin && (
                    <td>
                      <button
                        style={{ margin: "1%" }}
                        onClick={() => onEdit(player)}
                        className="btn btn_primary text_upp"
                      >
                        Edit
                      </button>

                      <button
                        style={{ margin: "1%" }}
                        onClick={() => onDelete(player)}
                        className="btn btn_primary text_upp"
                      >
                        Delete
                      </button>
                    </td>
                  )}

                  {isCoach && (
                    <td>
                      <button
                        style={{ margin: "1%" }}
                        onClick={() =>
                          handleDelete(
                            player?.PlayerId,
                            player?.FirstName,
                            player?.LastName
                          )
                        }
                        className="btn btn_primary text_upp"
                      >
                        Delete
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
const Manage50TeamPlayers = () => {
  let { TeamId } = useParams();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deletePlayerInfo, setDeletePlayerInfo] = useState({});
  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const getPlayersHandler = async (TeamId) => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await axios.get(API_URLS.GET_PLAYERS_BY_TEAM_ID, {
        params: {
          SelectedTeamId: TeamId,
        },
      });
      setPlayers(data?.result);
      setLoading(false);
    } catch (error) {
      alert(error.response.data.message);
      setLoading(false);
    }
  };
  const handleDeletePlayer = async (TeamId, PlayerId) => {
    try {
      setDeleteLoading(true);
      await axios.delete(API_URLS.DELETE_PLAYERS_BY_ID_TEAM_ID, {
        params: {
          TeamId,
          PlayerId,
        },
      });
      setDeletePlayerInfo(null);
      getPlayersHandler(TeamId);
      setDeleteLoading(false);
    } catch (error) {
      alert(error.response.data.message);
      setDeleteLoading(false);
    }
  };
  useEffect(() => {
    getPlayersHandler(TeamId);
  }, []);
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="spinner" />
      </div>
    );
  }
  return (
    <Fragment>
      <PlayerTable
        players={players}
        onRefresh={() => getPlayersHandler(TeamId)}
        onEdit={(player) => {
          setSelectedPlayer(player);
        }}
        setPlayers
        onDelete={(player) => {
          setDeletePlayerInfo({
            TeamId: player.TeamId,
            PlayerId: player.PlayerId,
          });
        }}
      />

      <EditPlayerModal
        isOpen={!!selectedPlayer?.PlayerId}
        onClose={() => setSelectedPlayer(null)}
        Player={selectedPlayer}
        getPlayersHandler={getPlayersHandler}
      />

      {deletePlayerInfo?.PlayerId && (
        <Alert
          message="Are you sure want to delete this player?"
          confirmPress={() =>
            handleDeletePlayer(
              deletePlayerInfo.TeamId,
              deletePlayerInfo.PlayerId
            )
          }
          disable={deleteLoading}
          cancelPress={() => setDeletePlayerInfo(null)}
        />
      )}
    </Fragment>
  );
};

export { Manage50TeamPlayers };
