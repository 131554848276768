import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { API_URLS, BASE_URL } from "../../../../Utils";
import AllPlayerPopup from "../Pop-ups/AllPlayerPopUp/AllPlayerPopup";

// Custom Arrow Component
const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button
      className={`${className} slick-prev btn_slick`}
      onClick={onClick}
    ></button>
  );
};

const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button
      className={`${className} slick-next btn_slick`}
      onClick={onClick}
    ></button>
  );
};

const AllPlayers = () => {
  const [agentNames, setAgentNames] = useState([]);
  const [playerPhotos, setPlayerPhotos] = useState([]);
  const [showAllPlayer, setShowAllPlayer] = useState(false);

  const settingsPlayers = {
    infinite: true,
    dots: false,
    arrows: true,
    autoplay: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    autoplaySpeed: 4000,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "10%",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1560,
        settings: {
          slidesToShow: 4,
          centerPadding: "5%",
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          centerPadding: "0", // Avoid horizontal overflow
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerPadding: "30px", // Fixed value for consistency
        },
      },
    ],
  };

  const openAllPlayerModal = () => {
    setShowAllPlayer(true);
  };
  const closeAllPlayerModal = () => {
    setShowAllPlayer(false);
  };

  const getAllAgentsWithPics = async () => {
    try {
      const response = await axios.get(API_URLS.GET_All_Agents);

      if (response.status === 200) {
        if (response.data && response.data.data) {
          const playerList = response.data.data.result[0];
          //console.log("without player.png", playerList);
          if (playerList.length > 0) {
            const filteredPlayers = playerList.filter((player) => {
              return (
                typeof player.PlayerPhoto === "string" &&
                player.PlayerPhoto !== "player.png"
              );
            });

            const playerNames = filteredPlayers.map(
              (player) => `${player.FirstName} ${player.LastName}`
            );
            const playerPhotos = filteredPlayers.map(
              (player) =>
                `${BASE_URL}/api/v1/files/PlayerPhoto/${player.PlayerPhoto}`
            );

            setAgentNames(playerNames);
            setPlayerPhotos(playerPhotos);
          } else {
          }
        } else {
          alert("No data found");
        }
      } else {
        alert("Error fetching players");
      }
    } catch (error) {
      alert(error.message);
    }
  };
  useEffect(() => {
    getAllAgentsWithPics();
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section className="players_section pt-2 pb-3" id="allplayers">
      <div className="container">
        <div className="sc_title " style={{}}>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            {/* <small style={{ paddingTop: "10px", paddingLeft: "100px" }}>
              The Club Players
            </small> */}
            <a
              target="_blank"
              className="btn btn_primary outline br-50"
              style={{ marginLeft: "auto" }}
              onClick={openAllPlayerModal}
            >
              View All Player
            </a>
            {showAllPlayer && (
              <AllPlayerPopup
                isOpen={showAllPlayer}
                onClose={closeAllPlayerModal}
              />
            )}
          </div>

          <h3 shadow-text="Players">All Players</h3>
        </div>
      </div>
      <div className="slider" data-aos="fade">
        <div className="slick players_slick">
          <Slider {...settingsPlayers}>
            {agentNames.map((name, index) => (
              <div className="slick_item slick_item_override" key={index}>
                <img src={playerPhotos[index]} alt={name} />
                <h3>{name}</h3>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className="container pt-3">
        <div className="blog_wrap">
          <div className="title">
            <h3>Latest YouTube Video</h3>
            <a
              href="https://www.youtube.com/@proam50softball66"
              target="_blank"
              className="btn btn_primary outline br-50"
            >
              View All
            </a>
          </div>

          <div className="blog_grid">
            <div className="col" data-aos="fade">
              <img src="images/maxresdefault.jpg" alt="" />
              <div className="content">
                <h4>Free Tournament Lucky Draw</h4>
                <p>
                  Lucky Draw For Free Tournament, Or 12 Balls for the month of
                  April and May
                </p>
                <a
                  href="youtube.com/watch?v=da17jWeGPJw"
                  className="btn btn_primary br-50"
                >
                  Watch Video <img src="images/icons/arrow.png" alt="" />
                </a>
              </div>
            </div>
            <div className="col" data-aos="fade">
              <img src="images/unsplash_L-2p8fapOA8.png" alt="" />
              <div className="content">
                <span>New York, 19 april 2022</span>
                <h4>WESTSIDE SATURDAY FIELD 1</h4>
                <p>WESTSIDE SATURDAY FIELD 1</p>
                <a
                  href="https://www.youtube.com/watch?v=I1dzoZTM768"
                  className="btn btn_primary br-50"
                >
                  Watch Now <img src="images/icons/arrow.png" alt="" />
                </a>
              </div>
            </div>
            <div className="col" data-aos="fade">
              <img src="images/unsplash_j5kEQ1JLqZk.png" alt="" />
              <div className="content">
                <span>New York, 19 april 2022</span>
                <h4>WESTSIDE SATURDAY FIELD 2</h4>
                <p>WESTSIDE SATURDAY FIELD 2</p>
                <a
                  href="https://www.youtube.com/watch?v=yd3FQ-rnH5g"
                  className="btn btn_primary br-50"
                >
                  Watch Now <img src="images/icons/arrow.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { AllPlayers };
