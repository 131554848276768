import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import axios from "axios";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";

import { API_URLS, BASE_URL } from "../../../../Utils";

const EditSchedule = ({ isOpen, onClose, ScheduleID, onUpdate }) => {
  const [tournamentList, setTournamentList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [selectedYear, setSelectedYear] = useState("2024");
  const [scheduleById, setScheduleById] = useState(null);
  const [selectedTeamRegTournament, setSelectedTeamRegTournament] =
    useState("");
  const [Bracket, setBracket] = useState("0");
  const [ddldays, setddldays] = useState("");
  const [ddlDivision, setddlDivision] = useState("0");
  const [date, setDate] = useState("");
  const [txtTime, settxtTime] = useState("");
  const [ddlVisitorTeamNames, setddlVisitorTeamNames] = useState("");
  const [VisitorScores, setVisitorScores] = useState("");
  const [HomeScores, setHomeScores] = useState("");
  const [ddlHomeTeamNames, setDdlHomeTeamNames] = useState("");
  const [GameNumber, setGameNumber] = useState(0);
  const [playingField, setPlayingField] = useState("");
  const [parkId, setParkId] = useState(0);

  const getScheduleById = (ScheduleID) => {
    const url = `${BASE_URL}/api/v1/web/park/get_schedule_by_Id?ScheduleId=${ScheduleID}`;
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        const scheduleData = data.data.result[0];
        //console.log("schedule Data", scheduleData);
        setScheduleById(scheduleData);
        setSelectedYear(scheduleData.Year.toString());
        setSelectedTeamRegTournament(scheduleData.SeasonOrTour);
        setBracket(scheduleData.BracketNumber.toString());
        setddldays(scheduleData.DayOfWeek);
        setddlDivision(scheduleData.Division);
        setDate(new Date(scheduleData.Date));
        settxtTime(scheduleData.Time);
        setddlVisitorTeamNames(scheduleData.VisitorTeam);
        setDdlHomeTeamNames(scheduleData.HomeTeam);
        setVisitorScores(scheduleData.VisitorScores);
        setHomeScores(scheduleData.HomeScores);
        setPlayingField(scheduleData.FieldNumber.toString());
        setGameNumber(parseInt(scheduleData.GameNumber, 10));
        setParkId(scheduleData.ParkId);
        getAllTeams(scheduleData.ParkId);
        fnTournamentList();
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const fnTournamentList = () => {
    const url = `${BASE_URL}/api/v1/web/park/get_all_proam_tournaments_by_year?year=${selectedYear}`;
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        setTournamentList(data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const getAllTeams = (SelectedParkId) => {
    const url = `${BASE_URL}/api/v1/web/team/Get_Team_By_ParkId_With_Type?SelectedParkId=${SelectedParkId}&Type=Tournament`;
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        //console.log("teamList", data);
        setTeamList(data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
  const handleTimeChange = (newTime) => {
    settxtTime(newTime);
  };

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log("handleSubmit started");
    const formattedDate = date ? date.toISOString() : "";
    const formData = {
      Year: selectedYear,
      SeasonOrTour: selectedTeamRegTournament,
      DayOfWeek: ddldays,
      Type: "Tournament",
      Division: ddlDivision,
      Date: formattedDate,
      Time: txtTime,
      VisitorTeam: ddlVisitorTeamNames,
      HomeTeam: ddlHomeTeamNames,
      FieldNumber: playingField.toString(),
      //ParkId: selectedTeamRegTournament,
      ParkId: parkId,
      ScheduleId: ScheduleID,
      GameNumber: GameNumber,
      BracketNumber: Bracket,
      VisitorScores: parseInt(VisitorScores, 10),
      HomeScores: parseInt(HomeScores, 10),
    };
    const {
      SeasonOrTour,
      BracketNumber,
      VisitorTeam,
      HomeTeam,
      Time,
      Date,
      DayOfWeek,
    } = formData;
    if (!SeasonOrTour.trim()) {
      alert("Please Select Tournament from Drop-Down.", "", "info");
      return;
    }
    if (!BracketNumber.trim() || BracketNumber === "0") {
      alert("Please Select Bracket Number from Drop-Down.", "", "info");
      return;
    }
    if (!DayOfWeek.trim() || ddldays === "0") {
      alert("Please Select Day from Drop-Down.", "", "info");
      return;
    }
    if (!VisitorTeam.trim()) {
      alert("Please Select Visitor Team from Drop-Down.", "", "info");
      return;
    }
    if (!HomeTeam.trim()) {
      alert("Please Select Home Team from Drop-Down.", "", "info");
      return;
    }
    if (!Time || !Time.trim()) {
      alert("Please select a time.", "", "info");
      return;
    }
    if (!formattedDate.trim()) {
      alert("Please enter Date.");
      return;
    }

    //console.log("here is formData", formData);
    try {
      const url = API_URLS.UPDATE_SCHEDULE;
      //console.log("Before axios request");
      const response = await axios.patch(url, formData, {
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 10000, // Timeout after 10 seconds
      });
      //console.log("After axios request");
      if (response.status === 200) {
        alert("Schedule Updated Successfully");
        onUpdate();
        onClose();
      } else {
        alert("Failed to update schedule");
      }
    } catch (error) {
      //console.error("Unhandled Promise Rejection:", error);
      alert("An error occurred while processing the request.");
    }
    //console.log("handleSubmit completed");
  };
  useEffect(() => {
    if (typeof ScheduleID !== "undefined") {
      getScheduleById(ScheduleID);
    }
  }, [selectedYear, ScheduleID]);
  const customStyles = {
    content: {
      width: "80%",
      padding: "20px",
      overflowY: "auto",
      maxHeight: "96%",
      margin: "auto",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Rules Modal"
      className="modal-content orange-scrollbar"
      overlayClassName="modal-overlay"
      style={{ ...customStyles }}
    >
      <div className="form_wrap teamReg_form">
        <div className="close-button">
          <h3>Update Schedule</h3>
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg "
            onClick={onClose}
          >
            <path
              d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
              fill="#1B1B1B"
            />
            <path
              d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
              fill="#1B1B1B"
            />
            <path
              d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
              fill="#1B1B1B"
            />
          </svg>
        </div>
        <form
          onSubmit={handleSubmit}
          className="row"
          style={{ marginRight: "10px", marginLeft: "1px" }}
        >
          {scheduleById && (
            <React.Fragment>
              <div className="col-md-12 input_group">
                <label htmlFor="year">Year *</label>
                <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="input input_select"
                >
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                </select>
              </div>
              <div className="col-md-12 input_group">
                <label htmlFor="selectedTournament">
                  Select Tournament
                  <span className="edd-required-indicator">*</span>
                </label>
                <select
                  id="selectedTournament"
                  value={selectedTeamRegTournament}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setSelectedTeamRegTournament(e.target.value);
                    getAllTeams(parseInt(e.target.value, 10));
                  }}
                  className="input input_select"
                >
                  {tournamentList.data?.result.map((tournament) => (
                    <option key={tournament.ParkId} value={tournament.ParkId}>
                      {tournament.FirstName} - {tournament.SeasonOrTour}{" "}
                      {/* Add tournament name */}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="Bracket">
                  Bracket<span className="edd-required-indicator">*</span>
                </label>
                <select
                  id="Bracket"
                  style={{ width: "100%" }}
                  value={Bracket}
                  onChange={(e) => setBracket(e.target.value)}
                  className="input input_select"
                >
                  <option selected="selected" value="0">
                    Select
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                </select>
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="ddldays">Day *</label>
                <select
                  id="ddldays"
                  style={{ width: "100%" }}
                  value={ddldays}
                  onChange={(e) => setddldays(e.target.value)}
                  className="input input_select"
                >
                  <option selected="selected" value="0">
                    Select
                  </option>
                  <option Value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                  <option value="Sunday">Sunday</option>
                </select>
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="ddlDivision">Division</label>
                <select
                  id="ddlDivision"
                  style={{ width: "100%" }}
                  value={ddlDivision}
                  onChange={(e) => setddlDivision(e.target.value)}
                  className="input input_select"
                >
                  <option selected="selected" value="0">
                    Select
                  </option>
                  <option Value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="D">D</option>
                  <option value="D Adv">D Adv</option>
                  <option value="D Rec">D Rec</option>
                  <option value="E">E</option>
                </select>
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="txtTime">
                  Time
                  <span className="edd-required-indicator">*</span>
                </label>
                <TimePicker
                  id="txtTime"
                  value={txtTime}
                  onChange={handleTimeChange}
                  format="hh:mm a" // 12-hour format with AM/PM
                  clockIcon={null} // Optional: Remove default clock icon if needed
                  disableClock={true} // Ensure the clock is enabled
                />
              </div>
              <div className="col-md-6 input_group">
                <label>
                  Date
                  <span className="edd-required-indicator">*</span>
                </label>
                <DatePicker
                  selected={date ? new Date(date) : null}
                  onChange={handleDateChange}
                  className="input"
                />
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="ddlVisitorTeamNames">
                  Visitor Team Name{" "}
                  <span className="edd-required-indicator">*</span>
                </label>

                <select
                  id="ddlVisitorTeamNames"
                  style={{ width: "100%" }}
                  value={ddlVisitorTeamNames}
                  onChange={(e) => setddlVisitorTeamNames(e.target.value)}
                  className="input input_select"
                >
                  {/* Rendering visitor team name first */}
                  {teamList.data?.result.map((team) => {
                    if (team.TeamName === ddlVisitorTeamNames) {
                      return (
                        <option key={team.TeamId} value={team.TeamName}>
                          {team.TeamName}
                        </option>
                      );
                    }
                    return null;
                  })}
                  {/* Rendering other teams */}
                  {teamList.data?.result.map((team) => {
                    if (team.TeamName !== ddlVisitorTeamNames) {
                      return (
                        <option key={team.TeamId} value={team.TeamName}>
                          {team.TeamName}
                        </option>
                      );
                    }
                    return null;
                  })}
                </select>
              </div>
              <div className="col-md-12 input_group">
                <label htmlFor="txtScores">Scores</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="number"
                    value={VisitorScores}
                    className="input"
                    placeholder="Visitor Score"
                    onChange={(e) => setVisitorScores(e.target.value)}
                  />
                  <input
                    type="number"
                    style={{
                      marginBottpm: "-20px",
                      marginLeft: "5px",
                    }}
                    value={HomeScores}
                    className="input"
                    placeholder="Home Score"
                    onChange={(e) => setHomeScores(e.target.value)}
                  />
                </div>
              </div>
              <div
                className="col-md-12 input_group"
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <label
                  htmlFor="ddlHomeTeamNames"
                  style={{ marginRight: "10px" }}
                >
                  Home Team Name
                  <span className="edd-required-indicator">*</span>
                </label>
                <select
                  id="ddlHomeTeamNames"
                  style={{ flex: "1" }}
                  value={ddlHomeTeamNames}
                  onChange={(e) => setDdlHomeTeamNames(e.target.value)}
                  className="input input_select"
                >
                  {teamList.data?.result.map((team) => {
                    if (team.TeamName === ddlHomeTeamNames) {
                      return (
                        <option key={team.TeamId} value={team.TeamName}>
                          {team.TeamName}
                        </option>
                      );
                    }
                    return null;
                  })}
                  {teamList.data?.result.map((team) => {
                    if (team.TeamName !== ddlHomeTeamNames) {
                      return (
                        <option key={team.TeamId} value={team.TeamName}>
                          {team.TeamName}
                        </option>
                      );
                    }
                    return null;
                  })}
                </select>
                <label
                  htmlFor="txtScores"
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  Game Number
                </label>
                <input
                  type="number"
                  value={GameNumber}
                  className="input"
                  placeholder="Game Number"
                  onChange={(e) => setGameNumber(parseInt(e.target.value, 10))}
                  style={{ flex: "1" }}
                />
              </div>
              <div
                className="col-md-12 input_group"
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <label htmlFor="playingField" style={{ marginRight: "10px" }}>
                  Playing Field
                </label>
                <select
                  id="playingField"
                  style={{ flex: "1" }}
                  value={playingField}
                  onChange={(e) => setBracket(e.target.value)}
                  className="input input_select"
                >
                  <option selected="selected" value="0">
                    Select
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
                <span>or</span>
                <input
                  type="text"
                  value={playingField}
                  className="input"
                  placeholder="Playing Field"
                  onChange={(e) => setPlayingField(e.target.value)}
                  style={{ flex: "1" }}
                />
              </div>
              <div className="col-md-12 btn_wrap">
                <button type="submit" className="btn btn_primary w-100">
                  Update
                </button>
              </div>
            </React.Fragment>
          )}
        </form>
      </div>
    </Modal>
  );
};

export { EditSchedule };
