import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";

import { API_URLS } from "../../../Utils";

import Edit50Tournaments from "./components/Edit50Tournaments";
import AddProamTournament from "./components/AddProamTournament";

const TournamentTable = ({ tournaments, onEdit, refreshTournament }) => {
  const [showSchedule, setShowSchedule] = useState(false);
  const openScheduleModal = () => {
    setShowSchedule(true);
  };
  const closeScheduleModal = () => {
    setShowSchedule(false);
  };
  return (
    <div className="container">
      <div className="tabs_container">
        <div className="tab_content active" data-tab="Schedules">
          <center>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
              }}
            >
              Manage ProAm Tournaments
            </h2>
          </center>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}
          >
            <button
              className="btn btn_primary text_upp"
              onClick={openScheduleModal}
            >
              Add ProAm Tournament
            </button>
            {showSchedule && (
              <AddProamTournament
                isOpen={showSchedule}
                onClose={closeScheduleModal}
                refreshTournament={refreshTournament}
              />
            )}
          </div>

          <table>
            <thead>
              <tr>
                <th>Tournament Name</th>
                <th>Actions</th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="schedule-container orange-scrollbar">
          <table>
            <tbody>
              {tournaments?.map((tournament) => (
                <tr key={tournament?.ParkId}>
                  <td>{tournament?.FirstName}</td>
                  <td>
                    <button
                      className="btn btn_primary text_upp"
                      onClick={() => onEdit(tournament?.ParkId)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
const Manage50Tournaments = () => {
  const [loading, setLoading] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedParkId, setSelectedParkId] = useState(null);

  const getTournamentsHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await axios.get(API_URLS.GET_ALL_50_TOURNAMENTS);

      // Sort tournaments by ParkId in descending order (latest entry first)
      const sortedTournaments = data?.result[0].sort(
        (a, b) => b.ParkId - a.ParkId
      );

      setTournaments(sortedTournaments);
      //console.log("sorted tournaments", sortedTournaments);

      setLoading(false);
    } catch (error) {
      alert(error.response.data.message);
      setLoading(false);
    }
  };
  const openModal = (parkId) => {
    setSelectedParkId(parkId);
    setIsModalOpen(true);
    //console.log("state here", isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    getTournamentsHandler();
  }, []);
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        <div className="spinner" />
      </div>
    );
  }
  return (
    <Fragment>
      <TournamentTable
        tournaments={tournaments}
        onEdit={openModal}
        refreshTournament={getTournamentsHandler}
      />
      {isModalOpen && (
        <Edit50Tournaments
          onClose={closeModal}
          isOpen={openModal}
          parkId={selectedParkId}
          refreshTournament={getTournamentsHandler}
        />
      )}
    </Fragment>
  );
};

export { Manage50Tournaments };
